import * as React from "react";
import styled from "styled-components";
import { dispatch, useDispatch } from "react-redux";
import { Link } from "gatsby";
import { graphql } from "gatsby";
import pathIcon from "./pathIcon.png";

import Layout from "../components/layout";
import Seo from "../components/seo";
import { Path } from "slate";
import Button from "../components/shared/Button";
import introImage from "./paths/intro.png";
import landingImage from "./paths/landing.png";
import styledImage from "./paths/styled.png";
import { useLocation } from "@reach/router";
import { useEffect } from "react";
import { fetchUser } from "../../gatsby-browser";
import IntroToReactIcon from "./paths/course-icons/intro-to-react.png";
import UIIcon from "./paths/course-icons/ui.png";
import TodoListIcon from "./paths/course-icons/todo-list.png";
import MaterialUIIcon from "./paths/course-icons/material-ui.png";
import MaterialUIDashboardsIcon from "./paths/course-icons/material-ui-dashboards.png";
import ReactRouterIcon from "./paths/course-icons/react-router.png";
import ReduxIcon from "./paths/course-icons/redux.png";

const slugsToIcons = {
  "/intro": IntroToReactIcon,
  "/ui": UIIcon,
  "/todo-list": TodoListIcon,
  "/material-ui-course": MaterialUIIcon,
  "/material-ui/templates": MaterialUIDashboardsIcon,
  "/react-router": ReactRouterIcon,
  "/redux-toolkit": ReduxIcon,
};

const PathsTitle = styled.h1`
  margin-bottom: 45px;
`;

const PathGrid = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`;

const HomepageViewCurriculum = styled.div`
  margin: 40px;

  button {
    width: 300px;
  }

  @media (max-width: 700px) {
    margin: 8px;
    button {
      width: 100px !important;
      font-size: 14px;
      padding: 8px;
    }
  }
`;

const PathCard = styled(Link)`
  min-height: 200px;
  display: inline-flex;
  max-width: 550px;
  margin: 10px;
  padding: 20px;
  border: 1px solid silver;
  border-radius: 20px;
  background: white;

  @media (max-width: 870px) {
    max-width: 350px;
    margin: 5px;
    display: block;
    padding: 40px;
  }

  &:hover {
    background: #e3e7f1;

    .start-path-button {
      display: block;
      float: right;
    }
  }
`;

const PathTitle = styled.div`
  color: black;
  margin-top: 0px;
  display: grid;
  grid-template-columns: 1fr 117px;
  justify-content: space-between;

  @media (max-width: 870px) {
    display: block;
  }

  h3 {
    margin: inherit;
  }

  span {
    font-size: 12px;
    justify-self: right;
    display: inline-block;
  }
`;

const PathInfoContainer = styled.div`
  margin-left: 25px;
  width: 100%;
  .start-path-button {
    display: none;
  }
`;

const PathIcon = styled.img`
  height: 100px;
`;

const PathDescription = styled.p`
  font-size: 16px;

  overflow: hidden;
  display: block;
  max-height: 100px;
  text-overflow: ellipsis;
`;

const Banner = styled.div`
  background-color: antiquewhite;
  font-size: 16px;
  padding: 8px;
  text-align: center;
`;

const LessonCount = ({ count }) => {
  return <span> {count} Lessons </span>;
};

const Paths = ({
  homepagePreview = false,
  data: {
    allPath: { edges },
  },
}) => {
  const dispatch = useDispatch();
  const usedLocation = useLocation();
  function getPathIcon(i) {
    if (i % 3 === 0) {
      return introImage;
    } else if ((i + 1) % 3 === 0) {
      return landingImage;
    } else if ((i + 2) % 3 === 0) {
      return styledImage;
    }
  }

  const LayoutComp = homepagePreview ? "div" : Layout;

  useEffect(() => {
    if (usedLocation?.state?.showEmailConfirmBanner) dispatch(fetchUser());
  }, []);

  return (
    <LayoutComp maxWidth={1100}>
      <Seo title="React Lessons from React School" />
      {usedLocation?.state?.showEmailConfirmBanner && (
        <Banner>
          Account created and you are logged in!{" "}
          {/*<b> Please confirm your email within 24 hours. </b>{" "}*/}
        </Banner>
      )}
      {usedLocation?.search === "?just_confirmed_subscription" && (
        <Banner>Your email has been confirmed!</Banner>
      )}
      {!homepagePreview && (
        <PathsTitle style={{ textAlign: "center" }}>
          {" "}
          React Learning Modules
        </PathsTitle>
      )}
      <PathGrid>
        {edges
          .filter((edge) => edge.node.visibility === "published")
          .filter((edge) => edge.node.access === "free")
          .map(({ node: { title, slug, description, lessonCount } }, i) => (
            <PathCard to={slug} key={slug}>
              <PathIcon src={slugsToIcons[slug] || getPathIcon(i)} />
              <PathInfoContainer>
                <PathTitle>
                  <h3>{title}</h3>
                  <LessonCount count={lessonCount} />
                </PathTitle>
                <PathDescription> {description} </PathDescription>
                <Button className="start-path-button"> Go >> </Button>
              </PathInfoContainer>
            </PathCard>
          ))}
        {homepagePreview && (
          <HomepageViewCurriculum>
            {" "}
            <Link to="/curriculum">
              <Button>View all >></Button>{" "}
            </Link>
          </HomepageViewCurriculum>
        )}
      </PathGrid>
      {/*<PathsTitle style={{ textAlign: "center" }}>*/}
      {/*  {" "}*/}
      {/*  Mission Fullstack*/}
      {/*</PathsTitle>*/}
      <PathGrid>
        {edges
          .filter((edge) => edge.node.visibility === "published")
          .filter((edge) => edge.node.access === "pro")
          .map(({ node: { title, slug, description, lessonCount } }, i) => (
            <PathCard to={slug} key={slug}>
              <PathIcon src={getPathIcon(i)} />
              <PathInfoContainer>
                <PathTitle>
                  <h3>{title}</h3>
                  <LessonCount count={lessonCount} />
                </PathTitle>
                <PathDescription> {description} </PathDescription>
                <Button className="start-path-button"> Go >> </Button>
              </PathInfoContainer>
            </PathCard>
          ))}
      </PathGrid>
    </LayoutComp>
  );
};

export const pageQuery = graphql`
  query {
    allPath {
      edges {
        node {
          id
          title
          slug
          visibility
          description
          lessonCount
          access
        }
      }
    }
  }
`;

export default Paths;
